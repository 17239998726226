import { graphql, Link } from "gatsby";
import React from "react";
import { CategoryLayout, Tag } from "../components";
import styled from "styled-components";
import SEO from "react-seo-component";
import { useSiteMetadata } from "../hooks/use-site-metadata";

const Card = styled.div`
  padding: 12px;
  margin: 20px auto;
  color: var(--text);
  text-align: left;
  max-width: 100%;

  a {
    display: block;
  }

  h2 {
    text-align: left;
    padding-top: 0px;
    margin-bottom: 16px;
  }
`;

export default function SnippetsPage({ data }) {
  const { siteUrl, siteLanguage, siteLocale, twitterUsername } =
    useSiteMetadata();

  return (
    <>
      <SEO
        title="Bitesized Accessibility"
        titleTemplate=""
        description={`Here's a collection of articles to give you a taste of what the "Bitesized A11y Course" is all about`}
        pathname={siteUrl}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
      />
      <CategoryLayout heading={"Bitesized"}>
        {data.allMdx.nodes.map(({ id, frontmatter, excerpt, slug }) => (
          <Card key={id}>
            <Link to={`/${slug}`}>
              <h2>{frontmatter.title}</h2>
            </Link>
            <p>{frontmatter.description ? frontmatter.description : excerpt}</p>
          </Card>
        ))}
      </CategoryLayout>
    </>
  );
}

export const query = graphql`
  query Bitsized {
    allMdx(
      limit: 100
      filter: { frontmatter: { tags: { in: "bitesized" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        excerpt(pruneLength: 240)
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          tags
          author
          description
        }
        slug
      }
    }
  }
`;
